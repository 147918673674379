.chatbar {
    z-index: 10000;
    position: fixed;
    bottom: 100px;
    left: 25%;
    /* transform: translateX(-50%); */
    width: 60%; /* Adjust the width as needed */
    height: 50px; /* Adjust the height as per the font size and padding */
    border-radius: 10px;
    /* background-color: #f1f1f1; Example background color */
    display: flex;
    align-items: center;
    padding: 0 10px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow for aesthetics */
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(2px);
}

.chat-input {
    flex-grow: 1;
    border: none;
    border-radius: 10px;
    padding: 5px 10px;
    font-size: 14px;
    margin-right: 10px; /* Spacing between input and send button */
}

.send-button {
    background: none;
    border: none;
    cursor: pointer;
}

.send-button img {
    height: 24px; /* Adjust based on your icon size */
    width: auto;
}
