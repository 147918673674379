.dndflow {
  flex-direction: column;
  display: flex;
  height: 600px;
  padding-top: 25px;
}

.dndflow aside {
  border-right: 1px solid #eee;
  border-left: none;
  padding: 15px 10px;
  font-size: 12px;
  height: 610px;
  background-color: rgb(249, 250, 251);
}

.dndflow aside.edit-node-sidebar {
  border-right: none;
  border-left: 1px solid #eee;
  padding: 15px 10px;
  font-size: 12px;
  height: 610px;
  background-color: rgb(249, 250, 251);
  max-width: 500px;
  min-width: 500px;
}



/* Right Node Edit sidebar */
.dndflow aside .description {
  margin-bottom: 10px;
  /* font-size: 16px;
  font-weight: bolder;
  display: flex;
  justify-content: center; */
}

.attr-key {
  margin-right: 10px;
}

.attr-value {
  border: 1px dashed;
  background: white;

  width: 100%;
}

.node-edit-sidebar-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.description h1 {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bolder;
  display: flex;
  justify-content: center;

}

.dndflow aside .description2 {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bolder;
  display: flex;
  justify-content: center;
}

/* .dndnode */

.dndflow .dndnode {
  padding: 6px;
  padding-left:15px;
  background: white;
  border: 1px solid black;
  border-radius: 2px;
  /* margin-bottom: 10px; */
  min-height: 40px;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  cursor: grab;
  display: flex;
  flex-direction: column;
  border-radius: 6px;
}

.nodes-wrapper-nodes-sidebar > * {
  margin-bottom: 7px;
}


.dndflow .dndnode .node-title-wrapper {
  display: flex;
}

.dndflow .dndnode .output-container  {
  display: flex;
}
.dndflow .dndnode .keyy  {
  margin-right: 8px;
  background: aliceblue;
}


.dndflow .dndnode .icon {
  margin-right: 10px;

}
.dndflow .dndnode .title {
  font-weight: bolder;
  text-decoration-line: underline;
}
.dndflow .dndnode .node-body {

}


.react-flow__node {
  width: 200px !important;
}

.react-flow__node.react-flow__node-output	 {
  border-color: blue;
  padding: 0;
}

.react-flow__node.react-flow__node-input	 {
  border-color: green;
  padding: 0;
}
.react-flow__node.react-flow__node-default	 {
  border-color: red;
  padding: 0;
}

.react-flow__node-input .react-flow__handle {
  background: black;
}

.react-flow__node-output .react-flow__handle {
  background: black;

}

.icon2 {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.small-logo-icon {
  height: 12px;
  display: flex;
}

.frosted-button {
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2); /* Optional shadow for aesthetics */
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px);
}

.dndflow .reactflow-wrapper {
  flex-grow: 1;
  height: 610px;
  width: 550px;
  background-color: rgb(249, 250, 251);
}

/* https://reactflow.dev/learn/customization/theming#overriding-built-in-classes */
.react-flow__edge-textbg {
  fill: aliceblue;
}

.react-flow__node-default, .react-flow__node-input, .react-flow__node-output {
  padding: 0;
  border-radius: 3px;
  width: 150px;
  font-size: 12px;
  color: #222;
  text-align: center;
  border-width: 1px;
  border-style: solid;
}


@media screen and (min-width: 768px) {
  .dndflow {
    flex-direction: row;
  }

  .dndflow aside {
    max-width: 250px;
    min-width: 250px;

    /* Need to do this for only the right sidebar  */
    /* max-width: 600px;
    min-width: 5000px; */

    overflow-y: scroll;
  }
}


.action-button {
  position: relative;
  z-index: 10000;
  margin: 3px;
  /* background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(2px); */

}

.action-button:hover {
  cursor: pointer !important;
}



.card {
  background: #333;
  border-radius: 10px;
  color: white;
  font-family: 'Arial', sans-serif;
  width: 300px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.title {
  font-weight: bold;
}

.icon-gear {
  font-size: 24px;
}

.content {
  font-size: 14px;
}

.computation .description {
  color: #aaa;
}

.computation .code {
  font-family: 'Courier New', monospace;
  background: #222;
  padding: 4px 8px;
  border-radius: 4px;
}

.io {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.input, .output {
  padding: 10px;
  background: #222;
  border-radius: 4px;
}

.output-container-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.output-container .value {
  max-width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

}

.picture-item {
  border: 1px solid rgb(79, 70, 229); /* Purple border */
  border-radius: 5px;       /* Rounded corners with 5px radius */
  box-shadow: 2px 2px 5px lightgray; /* Light gray shadow */
  font-size: 12px;
  z-index: 1000000;
}

.picture-item :hover {
  cursor: pointer !important;
}


.agg-row-class {
  font-weight: bold;
  text-decoration: underline;
}

.table-text {
  font-size:12px;
}

.small-text {
  padding: 17px;
}

@import "@yaireo/tagify/dist/tagify.css";

@keyframes ellipsis {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
  100% {
    content: ".";
  }
}

.animated-ellipsis::after {
  content: ".";
  animation: ellipsis 1.5s infinite;
}



.accounts-table {
  margin-top: 150px;
}

.p-datatable-thead {
  border: 1px solid rgb(204, 204, 204);
  /* border-bottom: 1px solid lightgray; */
}

div.dataTables_scrollHead table {
  border-top-left-radius: 4px;
}
 
div.dataTables_scrollHead th:first-child {
  border-top-left-radius: 4px;
}

tr {
  border-bottom: 1px solid rgb(204, 204, 204);
}

div.dataTables_scrollHead table {
  border-top-left-radius: 4px;
}
 
div.dataTables_scrollHead th:first-child {
  border-top-left-radius: 4px;
}

.table {
  border-left:0px !important;
  border-right:0px !important;
  }


[role="columnheader"] {
  font-size: 14px;
  /* Your styles here */
}